const HeaderPage = () => {
  function goHome() {
    window.location.href = "/";
  }
  return (
    <div className="header">
      <div className="header_icon" onClick={goHome} />
    </div>
  );
};

export default HeaderPage;
