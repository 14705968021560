export default function AboutPage() {
  return (
    <div className="about-container">
      <h1>About Me</h1>
      <p>
        Hey there,
        <br /> I'm Masika Abduli, or Mono. I'm a technology enthusiast who
        believes in creating clean, elegant code without relying on caffeine. I
        enjoy learning new things, including Japanese culture, and I'm also
        passionate about gaming.
        <br /> <br /> I'm a web developer and content creator. I like learning
        new things and making software to help the world. I'm curious and learn
        fast, and I'm not afraid of hard work. My goal is to take small pieces
        of code and turn them into big and useful programs.
        <br /> <br /> I enjoy solving problems day and night, and I love finding
        new ways to make things work better. If you need help with your software
        ideas, let's work together and make something amazing happen!🚀
      </p>
    </div>
  );
}
