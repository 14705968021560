import "./App.css";
import HeaderPage from "./components/Header";
import HomePage from "./components/Home";
import AboutPage from "./components/About"
import ContactPage from "./components/Contact";
import SocialPage from "./components/Socials";
import FooterPage from "./components/Footer";
import ProjectPage from "./components/Projects";

function App() {
  return (
    <div>
      <HeaderPage />
      <HomePage />
      <AboutPage />
      <ProjectPage />
      <SocialPage />
      <ContactPage />
      <FooterPage />
    </div>
  );
}

export default App;
