export default function ContactPage() {
  return (
    <form className="form">
      <div className="title">
        Hey,
        <br />
        <span>You can Reach me here !</span>
      </div>
      <input type="text" placeholder="Your Name" name="text" className="input" />
      <input type="email" placeholder="Email" name="email" className="input" />
      <textarea className="input" placeholder="What's up !"></textarea>
      <button className="button-confirm">Send →</button>
    </form>
  );
}
