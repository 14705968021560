import { useState } from "react";
import { FaRegFolderClosed, FaRegFolderOpen } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

const ProjectPage = () => {
  const [folderOpenXAWO, setFolderOpenXAWO] = useState(false);
  const [folderOpenSakuraScroll, setFolderOpenSakuraScroll] = useState(false);

  function toggleFolderXAWO() {
    setFolderOpenXAWO(!folderOpenXAWO);
  }

  function toggleFolderSakuraScroll() {
    setFolderOpenSakuraScroll(!folderOpenSakuraScroll);
  }

  return (
    <div className="project-container">
      <div className="folder" onClick={toggleFolderXAWO}>
        {folderOpenXAWO ? (
          <FaRegFolderOpen className="icon" />
        ) : (
          <FaRegFolderClosed className="icon" />
        )}
        <p>project XAWO</p>
      </div>
      {folderOpenXAWO && (
        <div className="folderPaper">
          <div className="heading">
            <h1>{">"} project XAWO</h1>
            <MdClose className="close" onClick={toggleFolderXAWO} />
          </div>
          <div className="body">
            <h1>Media Downloader - Download Any Content Easily</h1>
            <p>
              Unlock the ultimate convenience in media downloading with Media
              Downloader, your go-to app for effortlessly grabbing any media
              content from the web. Built with the power and flexibility of
              React Native, our app delivers a seamless experience across all
              your devices.
            </p>
            <ul>
              <li>Easy Link Paste</li>
              <li>Wide Media Support</li>
              <li>High-Speed Downloads</li>
              <li>User-Friendly Interface</li>
            </ul>
            <div className="link">
              <a
                href="https://xawo.pages.dev"
                target="_blank"
                onClick={toggleFolderXAWO}
              >
                Check the project
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="folder" onClick={toggleFolderSakuraScroll}>
        {folderOpenSakuraScroll ? (
          <FaRegFolderOpen className="icon" />
        ) : (
          <FaRegFolderClosed className="icon" />
        )}
        <p>project SakuraScroll</p>
      </div>
      {folderOpenSakuraScroll && (
        <div className="folderPaper">
          <div className="heading">
            <h1>{">"} project SakuraScroll</h1>
            <MdClose className="close" onClick={toggleFolderSakuraScroll} />
          </div>

          <div className="body">
            <h1>Anime Stream - Fast, Ad-Free Anime Watching</h1>
            <p>
              Dive into a world of anime like never before with Anime Stream,
              the ultimate destination for anime enthusiasts. Say goodbye to
              interruptions and slow load times, and say hello to a smooth,
              ad-free anime experience with our custom player.
            </p>
            <ul>
              <li>Ad-Free Viewing</li>
              <li>Custom Player</li>
              <li>Fast Streaming</li>
              <li>Extensive Library</li>
            </ul>
            <div className="link">
              <a
                href="https://sakurascroll.cc"
                target="_blank"
                onClick={toggleFolderSakuraScroll}
              >
                Check the project
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
