import { FaGithub } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BsFillThreadsFill } from "react-icons/bs";
import { useState } from "react";

export default function SocialPage() {
  const [modal, setModal] = useState(false);

  function openModal() {
    setModal(!modal);
  }
  return (
    <div className="social-container">
      <div className="toolbar-container">
        <div className="toolbar">
          <FaGithub className="so-icon" />
          <FaInstagram className="so-icon" />
          <BsFillThreadsFill className="so-icon" />
        </div>
      </div>
    </div>
  );
}
