export default function HomePage() {
  function downloadResume() {
    window.location.href = "../public/CV.pdf";
  }
  return (
    <div className="container">
      <h1>{">"}</h1>
      <div className="typewrite">
        <p>Supp,</p>
        <p> I am Masika [Mono],</p>
        <p> A c++ & web developer based in Germany.</p>
      </div>
      <div className="buttons">
        <a href="/CV.pdf" download>_get my resume</a>
      </div>
    </div>
  );
}
